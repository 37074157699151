import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import authReducer from './authentication/reducers';
import { chatReducer, SingleChatReducer } from './chat/reducers';
import { emailReducer, SingleEmailReducer } from './email/reducers';
import firebaseAuth from './firebase/auth/reducers';
import { fsCrudReducer, fsSingleCrudReducer } from './firebase/firestore/reducers';
import { readNotificationReducer } from './notification/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { userDataReducer } from './userData/reducers';
import { SingleChatConsoleAgent, SingleCounterChatConsoleAgent, SingleInfoChatConsoleAgent } from './console-agent/reducers';

const rootReducers = combineReducers({
  fb: firebaseReducer,
  fs: firestoreReducer,
  notification: readNotificationReducer,
  auth: authReducer,
  userData: userDataReducer,
  email: emailReducer,
  emailSingle: SingleEmailReducer,
  chatSingle: SingleChatReducer,
  chat: chatReducer,
  crud: fsCrudReducer,
  singleCrud: fsSingleCrudReducer,
  ChangeLayoutMode,
  firebaseAuth,
  consoleAgent: SingleChatConsoleAgent,
  infoChat: SingleInfoChatConsoleAgent,
  counterChat: SingleCounterChatConsoleAgent,
});

export default rootReducers;
