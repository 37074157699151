import axios from 'axios';
import { authHeader, SOCIAL_ENDPOINT } from './dataService';

export const socialNetworkClient = axios.create({
  baseURL: SOCIAL_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

class SocialNetworkService {
  static get(path = '', optionalHeader = {}) {
    return socialNetworkClient({
      method: 'GET',
      url: path,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static post(path = '', data = {}, optionalHeader = {}) {
    return socialNetworkClient({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static postFormData(path = '', data = {}, optionalHeader = {}) {
    return socialNetworkClient({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path = '', data = {}) {
    return socialNetworkClient({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = '', data = {}, optionalHeader = {}) {
    return socialNetworkClient({
      method: 'PUT',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static normalPut(path = '', data = {}) {
    return socialNetworkClient({
      method: 'PUT',
      url: path,
      data,
      headers: { ...authHeader() },
    });
  }

  static headersPut(path = '', data = {}, optionalHeader = {}) {
    return socialNetworkClient({
      method: 'PUT',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static delete(path = '') {
    return socialNetworkClient({
      method: 'DELETE',
      url: path,
      headers: { ...authHeader() },
    });
  }
}

socialNetworkClient.interceptors.response.use(
  response => response,
  error => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    const originalRequest = error.config;
    if (response) {
      if (response.status === 500) {
        // do something here
      } else {
        return originalRequest;
      }
    }
    return Promise.reject(error);
  },
);

export { SocialNetworkService };
