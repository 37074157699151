export const menuSider = profile => {
  // Profile 1 is Agent
  // Profile 2 Supervisor
  // Profile 3 is Administrator
  const isAgent = profile.find(prof => prof.profileId.toString() === '1');
  const isSupervisor = profile.find(prof => prof.profileId.toString() === '2');
  const isAdmin = profile.find(prof => prof.profileId.toString() === '3');

  const socialNetwork = [
    {
      modulo_ruta: 'redessociales',
      icon: 'share-2',
      modulo_nombre: 'Redes Sociales',
      idmodulo: 1,
      submodulo: [
        {
          parentid: 1,
          nombre: 'Consola Agentes',
          ruta: 'consola-agentes',
        },
        {
          parentid: 1,
          nombre: 'Chatbots',
          ruta: 'chatbots',
        },
        {
          parentid: 1,
          nombre: 'Mensajes Predeterminado',
          ruta: 'shortcuts',
        },
      ],
    },
  ];

  const socialNetworkAgent = [
    {
      modulo_ruta: 'redessociales',
      icon: 'share-2',
      modulo_nombre: 'Redes Sociales',
      idmodulo: 2,
      submodulo: [
        {
          parentid: 2,
          nombre: 'Consola Agentes',
          ruta: 'consola-agentes',
        },
        {
          parentid: 2,
          nombre: 'Chatbots',
          ruta: 'chatbots',
        },
      ],
    },
  ];

  const emailMenu = [
    {
      modulo_ruta: 'email',
      icon: 'mail',
      modulo_nombre: 'Email',
      idmodulo: 3,
      submodulo: [
        {
          parentid: 3,
          nombre: 'Bandeja',
          ruta: 'inbox',
        },
        {
          parentid: 3,
          nombre: 'Plantillas',
          ruta: 'template',
        },
      ],
    },
  ];

  const reportsMenu = [
    {
      modulo_ruta: 'report',
      icon: 'bar-chart',
      modulo_nombre: 'Reportes',
      idmodulo: 4,
      submodulo: [
        {
          parentid: 4,
          nombre: 'Email',
          ruta: 'email',
        },
        {
          parentid: 4,
          nombre: 'Chats',
          ruta: 'chat',
        },
        {
          parentid: 4,
          nombre: 'Interacciones',
          ruta: 'interacciones',
        },
        {
          parentid: 4,
          nombre: 'Logueo de Agentes',
          ruta: 'session',
        },
        {
          parentid: 4,
          nombre: 'Etiquetas',
          ruta: 'etiquetas',
        },
        {
          parentid: 4,
          nombre: 'TMO',
          ruta: 'tmo',
        },
        {
          parentid: 4,
          nombre: 'SLA',
          ruta: 'sla',
        },
      ],
    },
  ];

  const reportsMenuWithAgents = [
    {
      modulo_ruta: 'report',
      icon: 'bar-chart',
      modulo_nombre: 'Reportes',
      idmodulo: 5,
      submodulo: [
        {
          parentid: 5,
          nombre: 'Chats',
          ruta: 'chat',
        },
      ],
    },
  ];

  const userMenu = [
    {
      modulo_ruta: 'users',
      icon: 'user',
      modulo_nombre: 'User',
      idmodulo: 5,
      submodulo: [
        {
          parentid: 5,
          nombre: 'User',
          ruta: 'all',
        },
      ],
    },
  ];

  const settingMenu = [
    {
      modulo_ruta: 'configuracion',
      icon: 'settings',
      modulo_nombre: 'Configuración',
      idmodulo: 6,
      submodulo: [
        {
          parentid: 6,
          nombre: 'General',
          ruta: 'general',
        },
        {
          parentid: 6,
          nombre: 'Emails',
          ruta: 'proveedores',
        },
        {
          parentid: 6,
          nombre: 'Envio Masivo',
          ruta: 'envio-masivo',
        },
        {
          parentid: 6,
          nombre: 'Bases Numéricas',
          ruta: 'bases-numericas',
        },
      ],
    },
    {
      modulo_ruta: 'dashboard',
      icon: 'file',
      modulo_nombre: 'Dashboard',
      idmodulo: 7,
      submodulo: [
        {
          parentid: 7,
          nombre: 'Agentes',
          ruta: 'agentes',
        },
      ],
    },
  ];

  if (isAdmin) {
    return [...userMenu, ...socialNetwork, ...emailMenu, ...reportsMenu, ...settingMenu];
  }

  if (isAgent) {
    return [...socialNetworkAgent, ...emailMenu, ...reportsMenuWithAgents];
  }

  if (isSupervisor) {
    return [...socialNetwork, ...emailMenu, ...reportsMenu, ...settingMenu];
  }

  return [...userMenu, ...socialNetwork, ...emailMenu];
};
