import { Menu } from 'antd';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useRouteMatch, useHistory } from 'react-router-dom';
import { menuSider } from './constants';

const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { userData } = useSelector(state => ({ userData: state.userData }));
  const { profile = [] } = userData.data;
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = useState(!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []);

  const onOpenChange = keys => {
    setOpenKeys(keys);
  };

  const onClick = item => {
    if (item.key === 'dashboard') {
      setOpenKeys([]);
    }
  };

  const activeSubmodulo = item => {
    const segundoSlashIndex = pathName.indexOf('/', pathName.indexOf('/') + 1);
    const tercerSlashIndex = pathName.indexOf('/', segundoSlashIndex + 1);
    const modulo_ruta = pathName.substring(segundoSlashIndex + 1, tercerSlashIndex);
    return modulo_ruta === item;
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      defaultSelectedKeys={!topMenu ? [`${mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]}`] : []}
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
      style={{ marginRight: '10px' }}
    >
      <Menu.Item
        key="dashboard"
        style={{ height: '100%' }}
        icon={
          <div className={`menu_content ${pathName === '/admin' && 'menu_content_active'}`} onClick={() => history.push('/admin')}>
            <div className="menu_content_icon">
              <FeatherIcon icon="home" />
            </div>
            <p>Home</p>
          </div>
        }
      ></Menu.Item>
      {Boolean(profile.length) &&
        menuSider(profile).map(item => {
          const hasSubModulo = item.submodulo;
          const MenuItem = hasSubModulo ? SubMenu : Menu.Item;
          return (
            <MenuItem
              key={item.idmodulo}
              icon={
                <div className={`menu_content ${activeSubmodulo(item.modulo_ruta) && 'menu_content_active'}`}>
                  <div className="menu_content_icon">
                    <FeatherIcon icon={item.icon} />
                  </div>
                  <p>{item.modulo_nombre}</p>
                </div>
              }
            >
              {hasSubModulo
                ? hasSubModulo.map((submodulo, index) => {
                    return (
                      <Menu.Item key={`${item.idmodulo}-${index}`}>
                        <NavLink to={`${path}/${item.modulo_ruta}/${submodulo.ruta}`}>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
                            <FeatherIcon icon="circle" className="submodule-icon-circle" /> {submodulo.nombre}
                          </div>
                        </NavLink>
                      </Menu.Item>
                    );
                  })
                : item.modulo_nombre}
            </MenuItem>
          );
        })}
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
