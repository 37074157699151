import actions from './actions';

const { SINGLE_EMAIL_BEGIN, SINGLE_EMAIL_SUCCESS, SINGLE_EMAIL_ERR, STAR_UPDATE_BEGIN, STAR_UPDATE_SUCCESS, STAR_UPDATE_ERR } = actions;

const initialState = {
  singleEmail: [],
  allCampaign: [],
  allMessage: [],
  newEmails: 0,
  activeCampaign: {},
  sLoading: false,
  loading: false,
  error: null,
};

const emailReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case STAR_UPDATE_BEGIN:
      return {
        ...state,
        sLoading: true,
      };
    case STAR_UPDATE_SUCCESS:
      return {
        ...state,
        allCampaign: data.all,
        activeCampaign: data.active,
        newEmails: data.newEmails,
        sLoading: false,
      };
    case STAR_UPDATE_ERR:
      return {
        ...state,
        error: err,
        sLoading: false,
      };
    default:
      return state;
  }
};

const SingleEmailReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SINGLE_EMAIL_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case SINGLE_EMAIL_SUCCESS:
      return {
        ...initialState,
        singleEmail: data,
        loading: false,
      };
    case SINGLE_EMAIL_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { SingleEmailReducer, emailReducer };
