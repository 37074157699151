import React from 'react';
import { Row, Col } from 'antd';
import { Container, Content } from './overview/style';

const AuthLayout = WraperContent => {
  return () => {
    return (
      <Container>
        <Content>
          <WraperContent />
        </Content>
      </Container>
    );
  };
};

export default AuthLayout;
