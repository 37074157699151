import actions from './actions';
import { initialChat } from './constant';

const initialState = {
  data: initialChat,
  loading: false,
  error: null,
  chatId: '',
};

const initialStateForm = {
  data: [],
  loading: false,
  error: null,
};

const initialStateCounter = {
  data: {},
  isNewMessage: false,
};

const {
  SINGLE_CHAT_BEGIN,
  SINGLE_CHAT_SUCCESS,
  SINGLE_CHAT_ERR,
  RELOAD_CHAT_ERR,
  RELOAD_CHAT_SUCCESS,
  CLEAR_CHAT_ACTIVE,
  SINGLE_INFO_CHAT_ERR,
  SINGLE_INFO_CHAT_BEGIN,
  SINGLE_INFO_CHAT_SUCCESS,
  COUNTER_CHAT_ADD,
  COUNTER_CHAT_CLEAR,
  IS_NEW_MESSAGE,
  SINGLE_CHAT_CLEAR,
} = actions;

const SingleChatConsoleAgent = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SINGLE_CHAT_CLEAR:
      return {
        data: initialChat,
        loading: false,
        error: null,
        chatId: '',
      };
    case SINGLE_CHAT_BEGIN:
      return {
        ...initialState,
        loading: true,
        data: initialChat,
      };
    case SINGLE_CHAT_SUCCESS:
      return {
        ...initialState,
        data,
        chatId: data._id,
        loading: false,
      };
    case SINGLE_CHAT_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    case RELOAD_CHAT_SUCCESS:
      return { ...initialState, chatId: data._id, data };
    case RELOAD_CHAT_ERR:
      return { ...initialState, error: err };
    case CLEAR_CHAT_ACTIVE:
      return { loading: false, data: initialChat, error: null, chatId: '' };
    default:
      return state;
  }
};

const SingleInfoChatConsoleAgent = (state = initialStateForm, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SINGLE_INFO_CHAT_BEGIN:
      return {
        ...initialStateForm,
        loading: true,
        data: [],
      };
    case SINGLE_INFO_CHAT_SUCCESS:
      return {
        ...initialStateForm,
        data,
        loading: false,
      };
    case SINGLE_INFO_CHAT_ERR:
      return {
        ...initialStateForm,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

const SingleCounterChatConsoleAgent = (state = initialStateCounter, action) => {
  const { type, data } = action;
  switch (type) {
    case COUNTER_CHAT_ADD:
      return { ...initialStateCounter, data };

    case COUNTER_CHAT_CLEAR:
      return { ...initialStateCounter, data };

    case IS_NEW_MESSAGE: {
      return { ...initialStateCounter, isNewMessage: data };
    }

    default:
      return state;
  }
};

export { SingleChatConsoleAgent, SingleInfoChatConsoleAgent, SingleCounterChatConsoleAgent };
