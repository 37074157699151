import { Spin } from 'antd';
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';

const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const ResetPassword = lazy(() => import('../container/profile/authentication/overview/ResetPassForm'));

const NotFound = () => {
  return <Redirect to="/" />;
};

const FrontendRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path="*" component={NotFound} />
        {/* <Route path="/resetpassword/:id" component={ResetPassword} /> */}
        <Route path="/resetpassword/:id" component={ResetPassword} />
        {/* <Route path="/resetpassword" component={ResetPassword} /> */}
        <Route exact path="/" component={Login} />
      </Suspense>
    </Switch>
  );
};

export default AuthLayout(FrontendRoutes);
