import { Avatar } from 'antd';
import FeatherIcon from 'feather-icons-react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import { checkActiveUser, handleActive } from '../../../container/chat/constants';
import { logOut } from '../../../redux/authentication/actionCreator';
import { setUserData } from '../../../redux/userData/actionCreator';
import { removeItem } from '../../../utility/localStorageControl';
import { addNotificationError } from '../../../utility/notifications';
import { Popover } from '../../popup/popup';

const AuthInfo = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector(state => ({ userData: state.userData }));
  const { lastname = '', name = '' } = userData.data;
  const { users } = useSelector(state => {
    return {
      users: state.crud.users,
      isLoading: state.crud.loading,
    };
  });

  const SignOut = e => {
    e.preventDefault();
    dispatch(axiosDataSubmit());
  };

  const axiosDataSubmit = data => {
    return async dispatch => {
      try {
        // TODO check logout
        // const response = await SocialService.post(apiRoutes.auth.logout);

        // if (!response.data) {
        //   await addNotificationError('Error');
        // } else {
        // }
        removeItem('access_token');
        dispatch(
          setUserData({
            name: 'User',
            lastname: '',
          }),
        );
        if (users && Object.keys(users).length > 0) {
          const userState = checkActiveUser(users);
          if (userState !== 'inactive') dispatch(handleActive(userState));
        }
        dispatch(logOut());
      } catch (err) {
        await addNotificationError(err);
      }
    };
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <ul className="user-dropdwon__links">
          <li>
            <Link to="#">
              <FeatherIcon icon="user" /> Perfil
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Cerrar Sesión
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <div className="nav-author">
        <div style={{ margin: 'auto', color: 'white' }}>{`${name} ${lastname}`}</div>
        <Link to="#" className="head-example">
          <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
        </Link>
        <Popover placement="bottomRight" content={userContent} action="click">
          <div className="icon-arrow-down">
            <FeatherIcon icon="chevron-down" size={25} color="#0098F8" />
          </div>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
