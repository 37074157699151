const actions = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',

  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  loginSuccess: data => {
    return {
      type: actions.LOGIN_SUCCESS,
      data,
    };
  },

  logoutSuccess: data => {
    return {
      type: actions.LOGOUT_SUCCESS,
      data,
    };
  },
};

export default actions;
