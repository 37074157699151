const actions = {
  SINGLE_CHAT_CLEAR: 'SINGLE_CHAT_CLEAR',
  SINGLE_CHAT_BEGIN: 'SINGLE_CHAT_BEGIN',
  SINGLE_CHAT_SUCCESS: 'SINGLE_CHAT_SUCCESS',
  SINGLE_CHAT_ERR: 'SINGLE_CHAT_ERR',
  SINGLE_INFO_CHAT_BEGIN: 'SINGLE_INFO_CHAT_BEGIN',
  SINGLE_INFO_CHAT_SUCCESS: 'SINGLE_INFO_CHAT_SUCCESS',
  SINGLE_INFO_CHAT_ERR: 'SINGLE_CHAT_INFO_ERR',
  RELOAD_CHAT_SUCCESS: 'RELOAD_CHAT_SUCCESS',
  RELOAD_CHAT_ERR: 'RELOAD_CHAT_ERR',
  CLEAR_CHAT_ACTIVE: 'CLEAR_CHAT_ACTIVE',
  COUNTER_CHAT_ADD: 'COUNTER_CHAT_ADD',
  COUNTER_CHAT_CLEAR: 'COUNTER_CHAT_CLEAR',
  IS_NEW_MESSAGE: 'IS_NEW_MESSAGE',

  singleChatClear: () => {
    return {
      type: actions.SINGLE_CHAT_CLEAR,
    };
  },

  singleChatBegin: () => {
    return {
      type: actions.SINGLE_CHAT_BEGIN,
    };
  },

  singleChatSuccess: data => {
    return {
      type: actions.SINGLE_CHAT_SUCCESS,
      data,
    };
  },

  singleChatErr: err => {
    return {
      type: actions.SINGLE_CHAT_ERR,
      err,
    };
  },

  singleInfoChatBegin: () => {
    return {
      type: actions.SINGLE_INFO_CHAT_BEGIN,
    };
  },

  singleInfoChatSuccess: data => {
    return {
      type: actions.SINGLE_INFO_CHAT_SUCCESS,
      data,
    };
  },

  singleInfoChatErr: err => {
    return {
      type: actions.SINGLE_INFO_CHAT_ERR,
      err,
    };
  },

  reloadChatSuccess: data => {
    return {
      type: actions.RELOAD_CHAT_SUCCESS,
      data,
    };
  },

  reloadChatErr: err => {
    return {
      type: actions.RELOAD_CHAT_ERR,
      err,
    };
  },

  clearChatActive: () => {
    return { type: actions.CLEAR_CHAT_ACTIVE };
  },

  counterChatAdd: data => {
    return { type: actions.COUNTER_CHAT_ADD, data };
  },

  counterChatClear: data => {
    return { type: actions.COUNTER_CHAT_CLEAR, data };
  },
  isNewMessage: data => {
    return { type: actions.IS_NEW_MESSAGE, data };
  },
};

export default actions;
