import actions from './actions';

const {
  FB_CHANGE_BEGIN,
  FB_CHANGE_SUCCESS,
  FB_CHANGE_ERR,

  FB_UPDATE_BEGIN,
  FB_UPDATE_SUCCESS,
  FB_UPDATE_PRIVATE_CHAT_SUCCESS,
  FB_UPDATE_ERR,

  DB_REPORTS,
  DB_REPORTS_SUCCESS,
  DB_REPORTS_ERR,

  RP_OPEN,
  RP_OPEN_SUCCESS,
  RP_OPEN_ERR,

  FB_READ_BEGIN,
  FB_READ_SUCCESS,
  FB_READ_ERR,

  FB_USERS_BEGIN,
  FB_USERS_SUCCESS,
  FB_USERS_ERR,

  FB_EMAIL_READ_BEGIN,
  FB_EMAIL_READ_SUCCESS,
  FB_EMAIL_READ_ERR,

  FB_UPDATE_EMAIL_SUCCESS,
  FB_NEW_EMAIL_SUCCESS,

  FB_SINGLE_DATA_BEGIN,
  FB_SINGLE_DATA_SUCCESS,
  FB_SINGLE_DATA_ERR,

  FB_AGENTS_STATE_BEGIN,
  FB_AGENTS_STATE_ERR,
  FB_AGENTS_STATE_SUCCESS,

  FB_REASIGNED_STATE_SUCCESS,

  FB_CHAT_AGENTS_STATE_BEGIN,
  FB_CHAT_AGENTS_STATE_SUCCESS,
  FB_CHAT_AGENTS_STATE_ERR,

  USER_CONNECT,
  USER_CONNECT_SUCCESS,
  USER_CONNECT_ERR,

  OPEN_CAMPAIGN,
  OPEN_CAMPAIGN_SUCCESS,
  OPEN_CAMPAIGN_ERR,

  CLOSED_CAMPAIGN,
  CLOSED_CAMPAIGN_SUCCESS,
  CLOSED_CAMPAIGN_ERR,

  OPEN_CAMPAIGN_CHANNEL,
  OPEN_CAMPAIGN_CHANNEL_SUCCESS,
  OPEN_CAMPAIGN_CHANNEL_ERR,

  CLOSED_CAMPAING_CHANNEL,
  CLOSED_CAMPAING_CHANNEL_SUCCESS,
  CLOSED_CAMPAING_CHANNEL_ERR,
} = actions;

const initialState = {
  data: {},
  changes: [],
  updates: {},
  users: {},
  counter: 0,
  lastMessage: {},
  lastChat: '',
  emails: [],
  lastEmail: '',
  updateEmail: '',
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
  agentsState: {},
  reasignedchat: '',
  reports: [],
  reportOpen: [],
  reportClosedCampaign: [],
  reportOpenCampaign: [],
  reportClosedCampaignChannel: [],
  reportOpenCampaignChannel: [],
  userConnect: [],
};

const initialStateSingle = {
  data: null,
  loading: false,
  error: null,
};

const fsCrudReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FB_CHANGE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FB_CHANGE_SUCCESS:
      return {
        ...state,
        changes: data,
        error: false,
        loading: false,
      };

    case FB_CHANGE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case FB_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case DB_REPORTS:
      return {
        ...state,
        loading: true,
      };

    case DB_REPORTS_SUCCESS:
      return {
        ...state,
        reports: data,
        loading: true,
      };

    case DB_REPORTS_ERR:
      return {
        error: err,
        loading: false,
      };

    case RP_OPEN:
      return {
        ...state,
        loading: true,
      };
    case RP_OPEN_SUCCESS:
      return {
        ...state,
        reportOpen: data,
        loading: true,
      };
    case RP_OPEN_ERR:
      return {
        error: err,
        loading: false,
      };

    case USER_CONNECT:
      return {
        ...state,
        loading: true,
      };
    case USER_CONNECT_SUCCESS:
      return {
        ...state,
        userConnect: data,
        loading: true,
      };
    case USER_CONNECT_ERR:
      return {
        error: err,
        loading: false,
      };

    case CLOSED_CAMPAIGN:
      return {
        ...state,
        loading: true,
      };
    case CLOSED_CAMPAIGN_SUCCESS:
      return {
        ...state,
        reportClosedCampaign: data,
        loading: true,
      };
    case CLOSED_CAMPAIGN_ERR:
      return {
        error: err,
        loading: false,
      };

    case OPEN_CAMPAIGN_CHANNEL:
      return {
        ...state,
        loading: true,
      };
    case OPEN_CAMPAIGN_CHANNEL_SUCCESS:
      return {
        ...state,
        reportOpenCampaignChannel: data,
        loading: true,
      };
    case OPEN_CAMPAIGN_CHANNEL_ERR:
      return {
        error: err,
        loading: false,
      };

    case CLOSED_CAMPAING_CHANNEL:
      return {
        ...state,
        loading: true,
      };
    case CLOSED_CAMPAING_CHANNEL_SUCCESS:
      return {
        ...state,
        reportClosedCampaignChannel: data,
        loading: true,
      };
    case CLOSED_CAMPAING_CHANNEL_ERR:
      return {
        error: err,
        loading: false,
      };

    case OPEN_CAMPAIGN:
      return {
        ...state,
        loading: true,
      };
    case OPEN_CAMPAIGN_SUCCESS:
      return {
        ...state,
        reportOpenCampaign: data,
        loading: true,
      };
    case OPEN_CAMPAIGN_ERR:
      return {
        error: err,
        loading: false,
      };

    case FB_UPDATE_SUCCESS:
      return {
        ...state,
        updates: {
          ...state.updates,
          [data.key]: data.counter,
        },
        lastMessage: {
          ...state.lastMessage,
          [data.key]: data.counter === 1 || data.counter === 0 ? data.lastMessage : state.lastMessage[data.key],
        },
        loading: true,
      };

    case FB_UPDATE_PRIVATE_CHAT_SUCCESS:
      return {
        ...state,
        lastChat: data,
        loading: true,
      };

    case FB_UPDATE_ERR:
      return {
        ...state,
        loading: true,
      };

    case FB_USERS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FB_USERS_SUCCESS:
      return {
        ...state,
        users: data,
        error: false,
        loading: false,
      };

    case FB_USERS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case FB_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FB_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case FB_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case FB_EMAIL_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FB_EMAIL_READ_SUCCESS:
      return {
        ...state,
        emails: data,
        error: false,
        loading: false,
      };

    case FB_UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        updateEmail: data,
        error: false,
        loading: false,
      };

    case FB_NEW_EMAIL_SUCCESS:
      return {
        ...state,
        lastEmail: data,
        error: false,
        loading: false,
      };

    case FB_EMAIL_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case FB_AGENTS_STATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FB_AGENTS_STATE_SUCCESS:
      return {
        ...state,
        agentsState: data,
        error: false,
        loading: false,
      };

    case FB_AGENTS_STATE_ERR:
      return {
        ...state,
        agentsState: data,
        error: true,
        loading: false,
      };

    case FB_REASIGNED_STATE_SUCCESS:
      return {
        ...state,
        reasignedchat: data,
      };

    case FB_CHAT_AGENTS_STATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FB_CHAT_AGENTS_STATE_SUCCESS:
      return {
        ...state,
        chatAgentsState: data,
        error: false,
        loading: false,
      };

    case FB_CHAT_AGENTS_STATE_ERR:
      return {
        ...state,
        chatAgentsState: data,
        error: true,
        loading: false,
      };

    default:
      return state;
  }
};

const fsSingleCrudReducer = (state = initialStateSingle, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FB_SINGLE_DATA_BEGIN:
      return {
        ...initialStateSingle,
        loading: true,
      };

    case FB_SINGLE_DATA_SUCCESS:
      return {
        ...initialStateSingle,
        data,
        error: false,
        loading: false,
      };

    case FB_SINGLE_DATA_ERR:
      return {
        ...initialStateSingle,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { fsCrudReducer, fsSingleCrudReducer };
