import actions from './actions';

const initialStateUpdate = {
  data: {
    data: {
      name: 'User',
      lastname: '',
    },
  },
  loading: false,
  error: null,
};

const { USER_DATA_BEGIN, USER_DATA_SUCCESS, USER_DATA_ERR } = actions;

const userDataReducer = (state = initialStateUpdate, action) => {
  const { type, data, err } = action;

  switch (type) {
    case USER_DATA_BEGIN:
      return {
        ...initialStateUpdate,
        loading: true,
      };
    case USER_DATA_SUCCESS:
      return {
        ...initialStateUpdate,
        data,
        loading: false,
      };
    case USER_DATA_ERR:
      return {
        ...initialStateUpdate,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { userDataReducer };
