import Styled, { keyframes } from 'styled-components';
import authBackground from '../../../../static/img/auth/bg_login.png';

const Container = Styled.div`
  width: 100%;
  height: 100vh;
  // background-image: url("${require('../../../../static/img/cereza/cereza_background.jpeg')}");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  .auth-side-content{
    text-align: center;
    margin: auto;
  }
`;

const Content = Styled.div`
    // padding: 100px;
    // @media only screen and (max-width: 1599px){
    //   padding: 50px;
    // }
    // @media only screen and (max-width: 991px){
    //   padding: 20px;
    // }
    @media only screen and (max-width: 767px){
      text-align: center;
    }
    .auth-content-figure{
      @media only screen and (max-width: 1199px){
        max-width: 420px;
      }
      @media only screen and (max-width: 991px){
        max-width: 100%;
      }
    }
`;

const flicker = keyframes`
0% {
  opacity: 0;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
}
100% {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
`;

const AuthWrapper = Styled.div`
height:100vh;
width:100vw;
background-image: url("${authBackground}");
background-size: cover;
background-repeat: no-repeat;
display: flex;
justify-content: center;
align-items: center;
.login-card-container{
  background-color: #fff;
  position:relative;
  padding: 2rem;
  border-radius: .8rem;
  @media only screen and (min-width: 1440px){
    width: 661px;
}
}
.text_title{
  font-weight:400;
  font-size: 20px;
  color: #000034;
  @media only screen and (min-width: 1440px){
    font-size: 25px;
  }
}
.text_copyright{
  font-size:12px;
  font-weight:400;
  color: #000034;
  text-align:center;
}
.image_container{
  display:flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  @media only screen and (min-width: 1440px){
    margin-bottom: 1.5rem; 
    gap: 2.5rem;
  }

}
.login-header{
  max-width: 230px;
  @media only screen and (min-width: 1440px){
    max-width: 375px;
    margin-top: 3rem;
  }
}
.auth-contents{
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 1440px){
    gap: 4.5rem;
  }
}

.ant-input-affix-wrapper{
  height: 48px;
  @media only screen and (min-width: 1440px){
    height: 64px;
  }
} 
.ant-form-item-control-input-content > .ant-input{
  height: 48px;
  @media only screen and (min-width: 1440px){
    height: 64px;
  }
}

.btn-signin{
 background: #00388B;
 border-radius:34px;
 height: 100%;
 padding: 10px 10px;
 width: 240px;
 @media only screen and (min-width: 1440px){
  padding: 18px 20px;
}
 span{
   font-size: 14px;
   font-weight: 700;
   @media only screen and (min-width: 1440px){
    font-size: 16px;
  }
 }
 &:disabled{
  background-color: #D0D0D0;
 }
}
.auth-form-action{
  display:flex;
  justify-content: space-between;
  margin: 15px 0 24px 0;
  @media only screen and (min-width: 1440px){
    margin: 30px 0 36px 0;
  }
}
.ant-form-item-control-input-content{
  display:flex;
  justify-content: center;
  min-height:64px;
}
.ant-form-item-control-input{
  min-height: 64px;
}
.ant-form-item{
  margin-bottom:12px;
  @media only screen and (min-width: 1440px){
    margin-bottom: 24px;
  }
}
.remember-account-pass-link{
  color: #000034;
  font-weight: 600;
  font-size:12px;
  @media only screen and (min-width: 1440px){
    font-size: 16px;
  }
}
.remember-account-link{
  color: #000034;
  font-weight: 400;
  font-size: 12px;
  @media only screen and (min-width: 1440px){
    font-size: 16px;
  }
}
.resetPassForm > .ant-form > div {
  margin-bottom: 7.5rem;
}
.btn-whatssap{
  display:flex;
  gap: .5rem;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: -13rem;
  background-color: #25D366;
  border: none;
  padding: 12px;
  border-radius: 50px;
  @media only screen and (min-width: 1440px){
    padding: 16px;
    right: -18rem;
  }
  &:hover {
    cursor: pointer;
  }
  p{
    margin:0;
    font-weight: 600;
    font-size: 12px;
    line-height:12px;
    color: #FFFFFF;
    @media only screen and (min-width: 1440px){
      font-size: 16px;
      line-height:16px;
    }
  }
  svg {
    width: 14px;
    height: 14px;
    @media only screen and (min-width: 1440px){
      width: 24px;
      height: 24px;
    }
  }
}
`;

export { Container, Content, AuthWrapper };
