export const initialChat = {
  accountId: -1,
  active_user: '',
  campaign: '',
  channel: '',
  client: { provider_type: '', provider_id: '', name: 'Loading...', _id: '' },
  closed: false,
  created_at: '',
  deleted: false,
  last_updated_at: '',
  messages: [],
  number_id: -1,
  origin: { from: '', current: '' },
  provider_type: 'LOADING',
  tags: [],
  totalMessageQty: -1,
  users: [],
  __v: -1,
  _id: '',
};
