import actions from './actions';

const { userDataBegin, userDataSuccess, userDataErr } = actions;

const setUserData = data => {
  return async dispatch => {
    console.log('data', data);
    try {
      dispatch(userDataBegin());

      return dispatch(userDataSuccess(data));
    } catch (err) {
      dispatch(userDataErr(err));
    }
  };
};

export { setUserData };
