import { Spin } from 'antd';
import React, { lazy, Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard = lazy(() => import('../../container/dashboard'));
const Configuracion = lazy(() => import('../../container/configuracion/Configuracion'));
const Campaign = lazy(() => import('../../container/configuracion/camapaign/Campaign'));
const ProviderSettings = lazy(() => import('../../container/configuracion/ProviderSettings'));
const EnvioMasivo = lazy(() => import('../../container/configuracion/EnvioMasivo'));
const NumberBases = lazy(() => import('../../container/configuracion/NumberBases/NumberBases'));
const Inbox = lazy(() => import('../../container/email/Email'));
const Users = lazy(() => import('../../container/users/AllUsers'));
const Chat = lazy(() => import('../../container/chat/ChatApp'));
const Chatbots = lazy(() => import('../../container/chatbot/Chatbot'));
const ChatbotBuilder = lazy(() => import('../../container/chatbot/ChatBotBuilder/ChatBotBuilder'));
const AgentConsole = lazy(() => import('../../container/agents/AgentConsole'));
const Shortcuts = lazy(() => import('../../container/shortcuts/Shortcuts'));
const AgentsSettings = lazy(() => import('../../container/dashboardSection/Dashboard'));
const ChatReport = lazy(() => import('../../container/report/overview/ChatReports/ChatReport'));
const EmailReport = lazy(() => import('../../container/report/overview/EmailReports/EmailReports'));
const SessionReport = lazy(() => import('../../container/report/overview/SessionReports/SessionReports'));
const TagReport = lazy(() => import('../../container/report/overview/TagReports/TagReport'));
const TmoReport = lazy(() => import('../../container/report/overview/TmoReports/TmoReport'));
const InteractionsReport = lazy(() => import('../../container/report/overview/InteractionsReport/InteractionsReports'));
const SlaReport = lazy(() => import('../../container/report/overview/SlaReports/SlaReports'));
const ConsoleAgent2 = lazy(() => import('../../container/consoleAgent/ConsoleAgent'));

const Admin = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/admin" component={Dashboard} />
        <Route path={`${path}/redessociales/console-agent`} component={Chat} />
        <Route path={`${path}/redessociales/chatbots`} component={Chatbots} />
        <Route path={`${path}/redessociales/consola-agentes`} component={AgentConsole} />
        <Route path={`${path}/redessociales/chatbot-builder`} component={ChatbotBuilder} />
        <Route path={`${path}/redessociales/shortcuts`} component={Shortcuts} />
        <Route path={`${path}/email/:page`} component={Inbox} />
        <Route path={`${path}/configuracion/general`} component={Configuracion} />
        <Route path={`${path}/configuracion/campaign/:campaignId`} component={Campaign} />
        <Route path={`${path}/configuracion/proveedores`} component={ProviderSettings} />
        <Route path={`${path}/configuracion/envio-masivo`} component={EnvioMasivo} />
        <Route path={`${path}/dashboard/agentes`} component={AgentsSettings} />
        <Route path={`${path}/report/session`} component={SessionReport} />
        <Route path={`${path}/report/chat`} component={ChatReport} />
        <Route path={`${path}/report/email`} component={EmailReport} />
        <Route path={`${path}/report/etiquetas`} component={TagReport} />
        <Route path={`${path}/report/tmo`} component={TmoReport} />
        <Route path={`${path}/users/all`} component={Users} />
        <Route path={`${path}/configuracion/bases-numericas`} component={NumberBases} />
        <Route path={`${path}/redessociales/inbox-all`} component={ConsoleAgent2} />
        <Route path={`${path}/report/interacciones`} component={InteractionsReport} />
        <Route path={`${path}/report/sla`} component={SlaReport} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
