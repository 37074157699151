const actions = {
  FB_CHANGE_BEGIN: 'FB_CHANGE_BEGIN',
  FB_CHANGE_SUCCESS: 'FB_CHANGE_SUCCESS',
  FB_CHANGE_ERR: 'FB_CHANGE_ERR',

  FB_READ_BEGIN: 'FB_READ_BEGIN',
  FB_READ_SUCCESS: 'FB_READ_SUCCESS',
  FB_READ_ERR: 'FB_READ_ERR',

  FB_UPDATE_BEGIN: 'FB_UPDATE_BEGIN',
  FB_UPDATE_SUCCESS: 'FB_UPDATE_SUCCESS',
  FB_UPDATE_PRIVATE_CHAT_SUCCESS: 'FB_UPDATE_PRIVATE_CHAT_SUCCESS',
  FB_UPDATE_READ_ERR: 'FB_UPDATE_READ_ERR',

  FB_USERS_BEGIN: 'FB_USERS_BEGIN',
  FB_USERS_SUCCESS: 'FB_USERS_SUCCESS',
  FB_USERS_ERR: 'FB_USERS_ERR',

  FB_UPDATE_EMAIL_SUCCESS: 'FB_UPDATE_EMAIL_SUCCESS',
  FB_NEW_EMAIL_SUCCESS: 'FB_NEW_EMAIL_SUCCESS',

  FB_EMAIL_READ_BEGIN: 'FB_EMAIL_READ_BEGIN',
  FB_EMAIL_READ_SUCCESS: 'FB_EMAIL_READ_SUCCESS',
  FB_EMAIL_READ_ERR: 'FB_EMAIL_READ_ERR',

  FB_SINGLE_DATA_BEGIN: 'FB_SINGLE_DATA_BEGIN',
  FB_SINGLE_DATA_SUCCESS: 'FB_SINGLE_DATA_SUCCESS',
  FB_SINGLE_DATA_ERR: 'FB_SINGLE_DATA_ERR',

  FB_AGENTS_STATE_BEGIN: 'FB_AGENTS_STATE_BEGIN',
  FB_AGENTS_STATE_SUCCESS: 'FB_AGENTS_STATE_SUCCESS',
  FB_AGENTS_STATE_ERR: 'FB_AGENTS_STATE_ERR',

  FB_REASIGNED_STATE_SUCCESS: 'FB_REASIGNED_STATE_SUCCESS',

  DB_REPORTS: 'DB_REPORTS',
  DB_REPORTS_SUCCESS: 'DB_REPORTS_SUCCESS',
  DB_REPORTS_ERR: 'DB_REPORTS_ERR',

  RP_OPEN: 'RP_OPEN',
  RP_OPEN_SUCCESS: 'RP_OPEN_SUCCESS',
  RP_OPEN_ERR: 'RP_OPEN_ERR',

  USER_CONNECT: 'USER_CONNECT',
  USER_CONNECT_SUCCESS: 'USER_CONNECT_SUCCESS',
  USER_CONNECT_ERR: 'USER_CONNECT_ERR',

  OPEN_CAMPAIGN: 'OPEN_CAMPAIGN',
  OPEN_CAMPAIGN_SUCCESS: 'OPEN_CAMPAIGN_SUCCESS',
  OPEN_CAMPAIGN_ERR: 'OPEN_CAMPAIGN_ERR',

  CLOSED_CAMPAIGN: 'CLOSED_CAMPAIGN',
  CLOSED_CAMPAIGN_SUCCESS: 'CLOSED_CAMPAIGN_SUCCESS',
  CLOSED_CAMPAIGN_ERR: 'CLOSED_CAMPAIGN_ERR',

  OPEN_CAMPAIGN_CHANNEL: 'OPEN_CAMPAIGN_CHANNEL',
  OPEN_CAMPAIGN_CHANNEL_SUCCESS: 'OPEN_CAMPAIGN_CHANNEL_SUCCESS',
  OPEN_CAMPAIGN_CHANNEL_ERR: 'OPEN_CAMPAIGN_CHANNEL_ERR',

  CLOSED_CAMPAING_CHANNEL: 'CLOSED_CAMPAING_CHANNEL',
  CLOSED_CAMPAING_CHANNEL_SUCCESS: 'CLOSED_CAMPAING_CHANNEL_SUCCESS',
  CLOSED_CAMPAING_CHANNEL_ERR: 'CLOSED_CAMPAING_CHANNEL_ERR',

  fbReasignedSuccess: data => {
    return {
      type: actions.FB_REASIGNED_STATE_SUCCESS,
      data,
    };
  },

  fbReadBegin: () => {
    return {
      type: actions.FB_READ_BEGIN,
    };
  },

  fbReadSuccess: data => {
    return {
      type: actions.FB_READ_SUCCESS,
      data,
    };
  },

  fbReadErr: err => {
    return {
      type: actions.FB_READ_ERR,
      err,
    };
  },

  closedCampaingByChannel: () => {
    return {
      type: actions.CLOSED_CAMPAING_CHANNEL,
    };
  },

  closedCampaingByChannelSuccess: data => {
    return {
      type: actions.CLOSED_CAMPAING_CHANNEL_SUCCESS,
      data,
    };
  },

  closedCampaingByChannelErr: err => {
    return {
      type: actions.CLOSED_CAMPAING_CHANNEL_ERR,
      err,
    };
  },

  openCampaing: () => {
    return {
      type: actions.OPEN_CAMPAIGN,
    };
  },

  openCampaingSuccess: data => {
    return {
      type: actions.OPEN_CAMPAIGN_SUCCESS,
      data,
    };
  },

  openCampaingErr: err => {
    return {
      type: actions.OPEN_CAMPAIGN_ERR,
      err,
    };
  },

  fbEmailReadBegin: () => {
    return {
      type: actions.FB_EMAIL_READ_BEGIN,
    };
  },

  fbEmailReadSuccess: data => {
    return {
      type: actions.FB_EMAIL_READ_SUCCESS,
      data,
    };
  },

  fbEmailReadErr: err => {
    return {
      type: actions.FB_EMAIL_READ_ERR,
      err,
    };
  },

  fbChangeBegin: () => {
    return {
      type: actions.FB_CHANGE_BEGIN,
    };
  },

  fbChangeSuccess: data => {
    return {
      type: actions.FB_CHANGE_SUCCESS,
      data,
    };
  },

  fbChangeErr: err => {
    return {
      type: actions.FB_CHANGE_ERR,
      err,
    };
  },

  fbUsersBegin: () => {
    return {
      type: actions.FB_USERS_BEGIN,
    };
  },

  fbUserSuccess: data => {
    return {
      type: actions.FB_USERS_SUCCESS,
      data,
    };
  },

  fbUsersErr: err => {
    return {
      type: actions.FB_USERS_ERR,
      err,
    };
  },

  fbUpdateBegin: () => {
    return {
      type: actions.FB_UPDATE_BEGIN,
    };
  },

  dbReports: () => {
    return {
      type: actions.DB_REPORTS,
    };
  },

  dbReportsSuccess: data => {
    return {
      type: actions.DB_REPORTS_SUCCESS,
      data,
    };
  },

  dbReportsErr: () => {
    return {
      type: actions.DB_REPORTS_ERR,
    };
  },

  rpOpen: () => {
    return {
      type: actions.RP_OPEN,
    };
  },

  rpOpenSuccess: data => {
    return {
      type: actions.RP_OPEN_SUCCESS,
      data,
    };
  },

  rpOpenErr: () => {
    return {
      type: actions.RP_OPEN_ERR,
    };
  },

  closedCampaing: () => {
    return {
      type: actions.CLOSED_CAMPAIGN,
    };
  },

  closedCampaingSuccess: data => {
    return {
      type: actions.CLOSED_CAMPAIGN_SUCCESS,
      data,
    };
  },

  closedCampaingErr: () => {
    return {
      type: actions.CLOSED_CAMPAIGN_ERR,
    };
  },

  openCampaingByChannel: () => {
    return {
      type: actions.OPEN_CAMPAIGN_CHANNEL,
    };
  },

  openCampaingByChannelSuccess: data => {
    return {
      type: actions.OPEN_CAMPAIGN_CHANNEL_SUCCESS,
      data,
    };
  },

  openCampaingByChannelErr: () => {
    return {
      type: actions.OPEN_CAMPAIGN_CHANNEL_ERR,
    };
  },

  fbUpdateBegin: () => {
    return {
      type: actions.FB_UPDATE_BEGIN,
    };
  },

  fbUpdateSuccess: data => {
    return {
      type: actions.FB_UPDATE_SUCCESS,
      data,
    };
  },

  fbUpdateChatSuccess: data => {
    return {
      type: actions.FB_UPDATE_PRIVATE_CHAT_SUCCESS,
      data,
    };
  },

  fbUpdateErr: err => {
    return {
      type: actions.FB_UPDATE_ERR,
      err,
    };
  },

  fbUpdateEmailSuccess: data => {
    return {
      type: actions.FB_UPDATE_EMAIL_SUCCESS,
      data,
    };
  },

  fbNewEmailSuccess: data => {
    return {
      type: actions.FB_NEW_EMAIL_SUCCESS,
      data,
    };
  },

  fbSingleDataBegin: () => {
    return {
      type: actions.FB_SINGLE_DATA_BEGIN,
    };
  },

  fbSingleDataSuccess: data => {
    return {
      type: actions.FB_SINGLE_DATA_SUCCESS,
      data,
    };
  },

  fbSingleDataErr: err => {
    return {
      type: actions.FB_SINGLE_DATA_ERR,
      err,
    };
  },

  dbUserConnect: () => {
    return {
      type: actions.USER_CONNECT,
    };
  },

  dbUserConnectSuccess: data => {
    return {
      type: actions.USER_CONNECT_SUCCESS,
      data,
    };
  },

  dbUserConnectErr: err => {
    return {
      type: actions.USER_CONNECT_ERR,
      err,
    };
  },

  fbAgentsStateBegin: () => {
    return {
      type: actions.FB_AGENTS_STATE_BEGIN,
    };
  },

  fbAgentsStateSuccess: data => {
    return {
      type: actions.FB_AGENTS_STATE_SUCCESS,
      data,
    };
  },

  fbAgentsStateErr: err => {
    return {
      type: actions.FB_AGENTS_STATE_ERR,
      err,
    };
  },
};

export default actions;
