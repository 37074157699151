import { ConfigProvider } from 'antd';
import es_ES from 'antd/es/locale/es_ES';
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ProtectedRoute from './components/utilities/protectedRoute';
import config from './config/config';
import { SocialService } from './config/dataService/socialService';
import { logOut } from './redux/authentication/actionCreator';
import store from './redux/store';
import { setUserData } from './redux/userData/actionCreator';
import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';
import { apiRoutes } from './utility/apiRoutes';
import { getItem, removeItem } from './utility/localStorageControl';
import { addNotificationError } from './utility/notifications';

const { theme } = config;

const ProviderConfig = () => {
  const { rtl, isLoggedIn, topMenu, darkMode } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
      auth: state.fb.auth,
    };
  });
  const [path, setPath] = useState(window.location.pathname);

  const fechedUserId = getItem('userId');
  const dispatch = useDispatch();

  // useEffect(() => {
  //   window.addEventListener('beforeunload', alertUser);
  //   return () => {
  //     window.removeEventListener('beforeunload', alertUser);
  //   };
  // }, []);

  // const alertUser = e => {
  //   e.preventDefault();
  //   if (users && Object.keys(users).length > 0) {
  //     const userState = checkActiveUser(users);
  //     if (userState) dispatch(handleActive(true));
  //   }
  //   e.returnValue = '';
  // };

  useEffect(() => {
    if (fechedUserId && isLoggedIn) {
      const getUserData = userId => {
        return async () => {
          const data = {
            user: userId,
          };

          try {
            const response = await SocialService.get(apiRoutes.auth.me, data);

            if (response && response.data && Object.keys(response.data).length) {
              dispatch(setUserData(response.data));
              let unmounted = false;
              if (!unmounted) {
                setPath(window.location.pathname);
              }
              // eslint-disable-next-line no-return-assign
              return () => (unmounted = true);
            }
            removeItem('access_token');
            dispatch(
              setUserData({
                name: 'User',
                lastname: '',
              }),
            );
            dispatch(logOut());
          } catch (err) {
            addNotificationError('Ha surgido un error. Porfavor intentelo de nuevo');
            removeItem('access_token');
            dispatch(
              setUserData({
                name: 'User',
                lastname: '',
              }),
            );
            dispatch(logOut());
          }
        };
      };

      dispatch(getUserData(fechedUserId));
    }
  }, [dispatch, fechedUserId, isLoggedIn, setPath]);

  return (
    <ConfigProvider locale={es_ES} direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router basename={process.env.PUBLIC_URL}>
          {!isLoggedIn ? <Route path="/" component={Auth} /> : <ProtectedRoute path="/admin" component={Admin} />}
          {isLoggedIn && (path == process.env.PUBLIC_URL || path == `${process.env.PUBLIC_URL}/`) && <Redirect to="/admin" />}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
