import { getItem } from '../../utility/localStorageControl';

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const EMAIL_ENDPOINT = process.env.REACT_APP_EMAIL_API_ENDPOINT;
export const SOCIAL_ENDPOINT = process.env.REACT_APP_SOCIAL_API_ENDPOINT;
export const OMNICANAL_ENDPOINT = process.env.REACT_APP_OMNICANAL_API_ENDPOINT;
export const REPORTS_ENDPOINT = process.env.REACT_APP_REPORTS_API_ENDPOINT;
export const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_API_ENDPOINT;
export const FULLFILLMENT_ENDPOINT = process.env.REACT_APP_FULLFILLMENT_API_ENDPOINT;

export const authHeader = () => ({
  Authorization: `Bearer ${getItem('access_token')}`,
});
