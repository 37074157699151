/* eslint-disable react/prop-types */
import React from 'react';

export const DoubleCheck = ({color}) => {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
    //   <path d="m2.394 13.742 4.743 3.62 7.616-8.704-1.506-1.316-6.384 7.296-3.257-2.486zm19.359-5.084-1.506-1.316-6.369 7.279-.753-.602-1.25 1.562 2.247 1.798z"></path>
    // </svg>
    <svg width="18" height="11" viewBox="0 0 18 11" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.8585 0.747437C13.2741 1.11112 13.3163 1.74288 12.9526 2.15852L10.1526 5.35852C9.7889 5.77416 9.15713 5.81627 8.7415 5.45259C8.32586 5.08891 8.28374 4.45715 8.64742 4.04151L11.4474 0.841509C11.8111 0.425872 12.4429 0.383755 12.8585 0.747437ZM17.6585 0.747437C18.0741 1.11112 18.1163 1.74288 17.7526 2.15852L10.7526 10.1585C10.5731 10.3636 10.3177 10.4866 10.0455 10.499C9.77321 10.5114 9.5077 10.4121 9.31035 10.2242L7.44751 8.45002L5.95258 10.1585C5.77311 10.3636 5.51771 10.4866 5.24546 10.499C4.97321 10.5114 4.7077 10.4121 4.51035 10.2242L0.310347 6.22415C-0.0895837 5.84327 -0.105022 5.21029 0.275864 4.81036C0.65675 4.41043 1.28973 4.39499 1.68966 4.77588L5.13431 8.0565L5.99795 7.06948L5.11035 6.22415C4.71042 5.84327 4.69498 5.21029 5.07586 4.81036C5.45675 4.41043 6.08973 4.39499 6.48966 4.77588L9.93431 8.0565L16.2474 0.841509C16.6111 0.425872 17.2429 0.383755 17.6585 0.747437Z" fill={color || '#53bdeb'} />
    </svg>
  );
};



