import Cookies from 'js-cookie';
import actions from './actions';

const { loginSuccess, logoutSuccess } = actions;

const login = () => {
  return async dispatch => {
    Cookies.set('logedIn', true);
    dispatch(loginSuccess(true));
  };
};

const logOut = () => {
  return async dispatch => {
    Cookies.remove('logedIn');
    dispatch(logoutSuccess(null));
  };
};

export { login, logOut };
