import { notification } from 'antd';

export const addNotificationSuccess = message => {
  notification.success({
    message,
  });
};

export const addNotificationError = message => {
  notification.error({
    message,
  });
};

export const addNotificationWarning = message => {
  notification.warning({
    message,
  });
};
